@import
  "code-button",
  url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'),
  "minima/skins/classic",
  "minima/initialize",
  "dti-layout";

.container {
  max-width: none;
}
