// Primary colors
$primary: #113249;
$primary-light: #44637d;
$primary-dark: #071e34;
$primary-very-light: #f1f7f9;

$secondary-color: #5B8F5B;
$quaternary-color: #395877;

$background-color: #FFFFFF;
$foreground-color: #000000;

// Accent colors
$accent: #02dcc7;
$accent-light: #6df1e6;
$accent-dark: #00a58b;

// Background colors
$body-bg: #ffffff;
$card-bg: $primary-very-light;

// Text colors
$text-color: $primary;
$heading-color: $accent-dark;

// Button colors
$button-bg: $primary;
$button-color: $accent;

// Link colors
$link-color: $accent;
$link-hover-color: $accent-dark;

// Visited links
a:visited {
  color: $accent-dark;
}

			  

// To overwride width of default files.


main.default-content {
  width: 100%;
  margin: 0;
  padding: 2em 0;
}

main.default-content #wrapper {
  max-width: none;
  margin: 0;
  padding: 0 20px;
}

main.default-content h1 {
  margin-top: 0;
  text-align: center;
}

main.default-content p {
  text-align: justify;
}

//header and footer
.hero-image {
    
  background-color: $primary;
  position: relative;
  height: 250px; /* adjust this height to your liking */
  overflow: hidden;
}

.hero-image:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: -1;
}

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: $accent;
  z-index: 1;
}

.page-link {
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
}


.nav-links a, .page-link {
    color: #ffffff;  // Make text white
    text-decoration: none;
}

.nav-links a:hover, .nav-links a:visited {
    text-decoration: none;
    color: $accent;  // Use your cyan accent color for visited links
}

.site-header {
    border-top: none;
    border-bottom: none;
    backgrond-color: $background-color;

    // Positioning context for the mobile navigation icon
    position: relative;
}

footer {
    background-color: $primary;
    color: $accent;
    height: 200px;
}


// Person cards

.person-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto; /* centers the list */
}

.person-card {
  transition: transform 0.2s ease-out;
  position: relative;
  display: flex;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: $card-bg;  
}

.person-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.person-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.person-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.person-info h3 {
  margin-top: 15px;
}

.person-info p {
  margin-bottom: 10px;
}


// Project cards

.project-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.project-card {
  transition: transform 0.2s ease-out;
  position: relative;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: $card-bg;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.project-image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  margin-bottom: 20px;
}

.project-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
}

.project-info p {
  margin-bottom: 40px;
}


// person page

.person-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
}

.person-image {
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.person-image img {
  width: 100%;
}

.person-header {
  display: flex;
  justify-content: flex-end;
}
.person-links {
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.person-links a {
  color: $quaternary-color;
  margin-right: 10px;
}

.person-links a:hover {
  color: $secondary-color;
}

.person-bio {
  grid-column: 1 / -1;
}
.person-publications {
  grid-column: 1 / -1;
}
.person-projects {
  grid-column: 1 / -1;
}
@media screen and (max-width: 768px) {
  .person-page {
    grid-template-columns: 1fr;
  }
  .person-header {
    justify-content: center;
    margin-top: 20px;
  }
}

.partner-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto; /* centers the list */
}

.partner-card {
  transition: transform 0.2s ease-out;
  position: relative;
  display: block;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  background-color: $card-bg;
  max-width: 100%;
  overflow: hidden;  /* Add this */
  box-sizing: border-box;  /* Add this to include padding in width calculation */
}

.partner-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}


.partner-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.partner-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


// Conference sessions

.schedule {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
  margin: 0 auto;
  grid-gap: 10px;
  margin-top: 20px;
  
}

.time-slot {
  font-weight: bold;
  grid-row: span 2;
}

.room-1, .room-2, .room-3 {
  background-color: #f8f8f8;
  padding: 10px;
}

.session {
  background-color: $background-color;
  padding: 10px;
}

.session-info {
  margin-bottom: 40px;
}

.plenary-session {
  transition: transform 0.2s ease-out;    
  position: relative;
  display: flex;
  padding: 10px;
  grid-row: span 1 / -1;
  border-radius: 5px;
  background-color: $card-bg;
}
.plenary-session:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
.card a {
    display: flex; /* Use flexbox to make the link fill the entire card */
    flex: 1; /* Allow the link to fill the entire card */
    text-decoration: none; /* Remove the underline from the link text */
    color: inherit;
}   
.card a:hover {
    text-decoration: none; /* Remove the underline from the link text */
    color: inherit;
}
.card a:visited {
    text-decoration: none;
    color: inherit;
}
.keynote-session, .presentation-session {
    transition: transform 0.2s ease-out;
    padding: 10px;
    border-radius: 5px;
  background-color: $card-bg;
}
.keynote-session:hover, .presentation-session:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.workshop-session, breakout-session {
  transition: transform 0.2s ease-out;
  padding: 10px;
  border-radius: 5px;
  background-color: $card-bg;
}
.workshop-session:hover, breakout-session {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.break-session, poster-session {
  transition: transform 0.2s ease-out;    
  padding: 10px;
  border-radius: 5px;
  background-color: $card-bg;
}
.break-session:hover, poster-session {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}

.learn-more {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: $button-bg;
  color: $button-color;
  font-size: 14px;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.learn-more:hover {
    background-color: $foreground-color;
}
